import {
    BulbTwoTone, FolderOutlined, HistoryOutlined, LeftOutlined, LockOutlined, QuestionCircleTwoTone,
    RightOutlined, UserOutlined, SunOutlined, SunFilled, FormOutlined, ContactsOutlined, ArrowsAltOutlined,
    DollarOutlined,
    SettingOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import { styled } from '@stitches/react';
import { Layout, Menu, Progress, Button, message } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FeedbackModal from './feedback-modal';
import { getUsage } from '../services/user';
import QuickSupportModal from './quick-support-modal';
import { getUserDataFromLocalStorage } from '../utils/useLocalStorage';
import { capitalize } from '../utils/utils';

const { Sider } = Layout;
// const chatSiderWidth = 256;
const chatSiderWidth = "33vh";
const collapsedSiderWidth = 80;

const TopContainer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '1rem'
})
const LogoContainer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})
const Logo = styled('img', {
    height: "80px",
    padding: '1rem',
})

const StyledMenu = styled(Menu, {
    width: '100%',
    '& .ant-menu-item:last-child': {
        marginTop: 'auto',
    },
})

const StyledSider = styled(Sider, {
    "& > .ant-layout-sider-children": {
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    height: '100vh',
    overflow: 'auto',
    position: 'fixed !important',
    left: 0,
    top: 0,
    bottom: 0,
})

export default function Sidebar({ onLogout, children, isDarkMode, toggleTheme }: { onLogout: () => void; children: React.ReactNode; isDarkMode: boolean; toggleTheme: () => void }) {
    const navigate = useNavigate();
    const [logoData, setLogoData] = React.useState<string | undefined>(undefined);
    const [collapsed, setCollapsed] = React.useState<boolean>(false);
    const [quickSupportModalOpen, setQuickSupportModalOpen] = React.useState(false);
    const [maxSentMessages, setMaxSentMessages] = React.useState<number>(0)
    const [currentSentMessages, setCurrentSentMessages] = React.useState<number>(0)

    const location = useLocation();
    const currentPath = location.pathname;
    const activeTab = `/${currentPath.split("/")[1]}`;

    const user = getUserDataFromLocalStorage();

    const referralLink = `${window.location.origin}/signup?referral_code=${user.referrals.code}`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink).then(() => {
            message.success('Referral link copied to clipboard!');
        }).catch(err => {
            message.error('Failed to copy referral link.');
        });
    };

    function getItems() {
        if (["technical"].includes(user.type)) {
            return [
                { key: "/courses", icon: <FolderOutlined />, label: `${capitalize(user.config.course_terminology)}s`, onClick: () => navigate("/courses") },
            ]
        }
        if (["student", "autodidact"].includes(user.type)) {
            return [
                { key: "/courses", icon: <FolderOutlined />, label: `${capitalize(user.config.course_terminology)}s`, onClick: () => navigate("/courses") },
                { key: "/chat-history", icon: <HistoryOutlined />, label: "Chat History", onClick: () => navigate("/chat-history") },
            ]
        }
        if (user.type === "professor") {
            return [
                { key: "/courses", icon: <FolderOutlined />, label: `${capitalize(user.config.course_terminology)}s`, onClick: () => navigate("/courses") },
                { key: "/chat-history", icon: <HistoryOutlined />, label: "Chat History", onClick: () => navigate("/chat-history") },
                { key: "/assigned-students ", icon: <ContactsOutlined />, label: "Assigned Students", onClick: () => navigate("/assigned-students") },
            ]
        }
        if (['admin', 'technical_admin'].includes(user.type)) {
            return [
                { key: "/courses", icon: <FolderOutlined />, label: `${capitalize(user.config.course_terminology)}s`, onClick: () => navigate("/courses") },
                { key: "/chat-history", icon: <HistoryOutlined />, label: "Chat History", onClick: () => navigate("/chat-history") },
                { key: "/assigned-students", icon: <ContactsOutlined />, label: "Assigned Students", onClick: () => navigate("/assigned-students") },
                { key: "/administration", icon: <FormOutlined />, label: "Administration", onClick: () => navigate("/administration") }
            ]
        }
        else {
            return []
        }
    }

    useEffect(() => {
        getUsage(user._id).then(data => {
            setCurrentSentMessages(data.current_sent_messages)
            setMaxSentMessages(data.max_sent_messages)
        })
    }, [user])

    useEffect(() => {
        setLogoData(user.config.logo)
    }, [user.config.logo])

    function calculateUsagePercentage() {
        if (maxSentMessages !== undefined && currentSentMessages !== undefined) {
            if (currentSentMessages > maxSentMessages) {
                return 100
            }
            return (currentSentMessages / maxSentMessages) * 100
        }
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <StyledSider
                trigger={null}
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                width={chatSiderWidth}
            >
                <div>
                    <TopContainer>
                        <LogoContainer><Logo
                            src={logoData}
                            alt={user.config.organization} />
                        </LogoContainer>
                        {(
                            ['student', 'autodidact', 'professor', 'admin', 'technical_admin', 'test'].includes(user.type)
                            && (maxSentMessages !== undefined && currentSentMessages !== undefined)
                            && !user.billing.pay_as_you_go
                        )
                            ?
                            <>
                                <p><i>Message Usage</i></p>
                                <Progress
                                    status='normal'
                                    type="circle"
                                    percent={calculateUsagePercentage()}
                                    size={collapsedSiderWidth}
                                    format={() => `${currentSentMessages} / ${maxSentMessages}`}
                                    strokeColor={{ '0%': '#108ee9', '100%': '#ed808b' }}
                                />
                            </>
                            : <div style={{ height: '100px' }} />}
                    </TopContainer>
                    <StyledMenu
                        selectedKeys={[activeTab]}
                        mode="vertical"
                        items={getItems()}
                    />
                </div>
                <StyledMenu
                    selectedKeys={[activeTab]}
                    mode="vertical"
                    items={[
                        ...(user.type !== 'technical' ? [{
                            key: "templates",
                            icon: <ArrowsAltOutlined />,
                            label: "Templates",
                            onClick: () => navigate("/users")
                        },] : []),
                        {
                            key: "quick-support",
                            icon: <QuestionCircleTwoTone
                                twoToneColor="blue" />,
                            label: "Get Quick Support",
                            onClick: () => setQuickSupportModalOpen(true)
                        },
                        {
                            key: "/profile",
                            icon: <UserOutlined />,
                            label: "Profile",
                            children: [
                                ...(user.type !== 'student' && user.type !== 'technical' ? [{
                                    key: "/subscription",
                                    icon: <DollarOutlined />,
                                    label: "Subscription",
                                    onClick: () => navigate("/subscription")
                                }] : []),
                                ...(user.type !== 'technical' ? [{
                                    key: "/settings",
                                    icon: <SettingOutlined />,
                                    label: "Settings",
                                    onClick: () => navigate("/settings")
                                }] : []),
                                {
                                    key: "/logout",
                                    icon: <LockOutlined />,
                                    label: "Log out",
                                    onClick: onLogout
                                },
                            ]
                        },
                        {
                            key: "share",
                            icon: <ShareAltOutlined />,
                            label: "Refer a Friend",
                            onClick: copyToClipboard
                        },
                        {
                            key: "theme_toggle",
                            icon: isDarkMode ? <SunOutlined style={{ color: 'white' }} /> : <SunFilled style={{ color: 'black' }} />,
                            label: "Switch Theme",
                            onClick: () => toggleTheme()
                        },
                        {
                            key: "collapse",
                            icon: collapsed ? <RightOutlined /> : <LeftOutlined />,
                            onClick: () => setCollapsed(prev => !prev),
                            style: { textAlign: 'center' }
                        },
                    ]} />
            </StyledSider>
            <Layout
                data-testid="main-content"
                className="site-layout"
                style={{
                    transition: '0.2s ease-in-out',
                    marginLeft: collapsed ? 80 : chatSiderWidth
                }}>
                {children}
                <QuickSupportModal
                    isOpen={quickSupportModalOpen}
                    setIsOpen={setQuickSupportModalOpen} />
            </Layout>
        </Layout>
    );
}
