import React, { useState, useEffect } from 'react';
import { Table, message } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { BackendTemplate, Template, VariableDescription } from '../services/templates';
import TemplateCreator from '../components/chat/template-creator';
import { updateUserTemplates } from '../services/user';
import { v4 as uuidv4 } from 'uuid';
import useUserData from '../hooks/useUser';
import { FlexMarginButton } from '../components/basic/buttons';

const UsersPage = () => {
    const [templates, setTemplates] = useState<Template[]>([]);
    const [editingTemplate, setEditingTemplate] = useState<Template | null>(null);
    const [isCreatorVisible, setIsCreatorVisible] = useState(false);
    const { user, updateUserDataTemplates } = useUserData();

    useEffect(() => {
        if (user && user.templates) {
            const updatedTemplates = user.templates.map((template: BackendTemplate) => ({
                ...template,
                ui_key: uuidv4()
            }));
            setTemplates(updatedTemplates);
        }
    }, [user.templates]);

    const updateBackendAndLocalStorage = async (updatedTemplates: Template[]) => {
        const BackendTemplates = updatedTemplates.map(({ ui_key, ...rest }) => rest);
        updateUserDataTemplates(BackendTemplates);

        const success = await updateUserTemplates(user._id, BackendTemplates);
        if (success) {
            message.success('Templates updated successfully');
        } else {
            message.error('Failed to update templates on the server');
        }
    };

    const handleEditTemplate = (template: Template) => {
        console.log('Edit template called', template);
        setEditingTemplate(template);
        setIsCreatorVisible(true);
    };

    const handleDeleteTemplate = async (template: Template) => {
        console.log('Delete template called', template);
        const updatedTemplates = templates.filter(t => t.ui_key !== template.ui_key);
        setTemplates(updatedTemplates);
        await updateBackendAndLocalStorage(updatedTemplates);
    };


    const handleSave = (updatedTemplate: Template): boolean => {
        const existingTemplate = templates.find(
            t => t.name.toLowerCase() === updatedTemplate.name.toLowerCase() && t.ui_key !== updatedTemplate.ui_key
        );

        if (existingTemplate) {
            message.error('A template with this name already exists');
            return false;
        }

        let updatedTemplates;
        if (templates.some(t => t.ui_key === updatedTemplate.ui_key)) {
            updatedTemplates = templates.map(t =>
                t.ui_key === updatedTemplate.ui_key ? updatedTemplate : t
            );
        } else {
            updatedTemplates = [...templates, updatedTemplate];
        }

        setTemplates(updatedTemplates);
        updateBackendAndLocalStorage(updatedTemplates);

        return true;
    };

    const columns = [
        { title: 'Name', dataIndex: 'name', key: 'name', width: '15%', },
        { title: 'Type', dataIndex: 'type', key: 'type', width: '15%', },
        {
            title: 'Variables',
            dataIndex: 'variables',
            key: 'variables',
            render: (variables: VariableDescription[]) => variables.map((v: VariableDescription) => v.variable).join('\n'),
            width: '25%',
        },
        {
            title: 'Descriptions',
            dataIndex: 'variables',
            key: 'descriptions',
            render: (variables: VariableDescription[]) => variables.map((v: VariableDescription) => v.description).join('\n'),
            width: '25%',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_: any, record: Template) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <FlexMarginButton onClick={(e) => {
                        e.stopPropagation();
                        handleEditTemplate(record);
                    }}>
                        <EditOutlined />
                    </FlexMarginButton>
                    <FlexMarginButton onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteTemplate(record);
                    }}>
                        <DeleteOutlined />
                    </FlexMarginButton>
                </div>
            ),
            width: '20%',
        },
    ];

    return (
        <div style={{ padding: '20px' }}>
            <h1>Templates</h1>
            <Table
                dataSource={templates}
                columns={columns}
                rowKey="ui_key"
                pagination={false}
                style={{ marginTop: 16 }}
            />
            <TemplateCreator
                visible={isCreatorVisible}
                onClose={() => setIsCreatorVisible(false)}
                onSave={handleSave}
                template={editingTemplate}
            />
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 16
            }}>
                <FlexMarginButton
                    type="primary"
                    onClick={() => {
                        setEditingTemplate(null);
                        setIsCreatorVisible(true);
                    }}
                >
                    + New Template
                </FlexMarginButton>
            </div>
        </div>
    );
};

export default UsersPage;