import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Typography, Space } from 'antd';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { SunOutlined, SunFilled } from '@ant-design/icons';
import { Container, SignupFormContainer, TopImages } from '../session-styles';
import { AbsoluteCenter } from '../styles';
import { SignupFormProps } from './signup-form';

const { Title } = Typography;

const KeyAccountSignupForm: React.FC<SignupFormProps> = ({ loginCallback }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [emailExists, setEmailExists] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            token: '',
            number_of_students: 10,
            number_of_messages: 300,
            pay_as_you_go: true
        },
        validate: (values) => {
            let errors: Record<string, string> = {};
            if (!values.name) {
                errors.name = 'Please enter your name.';
            }
            if (!values.email) {
                errors.email = 'Please enter your email.';
            }
            if (!values.password) {
                errors.password = 'Please enter your password.';
            }
            if (values.password !== values.confirmPassword) {
                errors.confirmPassword = 'Passwords do not match.';
            }
            if (emailExists) {
                errors.email = 'This email is already in use.';
            }
            if (!values.token) {
                errors.token = 'Please enter the verification token.';
            }
            return errors;
        },
        onSubmit: (values) => {
            if (emailExists) {
                message.error('This email is already in use. Please use a different email.');
                return;
            }
            setIsLoading(true);
            
            localStorage.setItem('signupFormData', JSON.stringify({ ...values, type: 'professor' }));
            fetch(`${process.env.REACT_APP_BACKEND_URL}/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: values.name,
                    email: values.email,
                    username: values.email,
                    password: values.password,
                    type: 'professor',
                    educator_email: '',
                    token: values.token,
                    number_of_students: values.number_of_students,
                    number_of_messages: values.number_of_messages,
                    stripe_customer_id: '',
                    paid: true,
                    payment_session_id: '',
                    pay_as_you_go: false
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Signup failed. Please check your details and try again.");
                    }
                    return response.json();
                })
                .then((data) => {
                    localStorage.setItem('user', JSON.stringify(data.user));
                    localStorage.setItem('access_token', data.access_token);
                    loginCallback();
                })
                .catch((error) => {
                    message.error(error.message || 'Signup Failed!');
                }).finally(() => {
                    setIsLoading(false);
                });
        },
        validateOnChange: false,
        validateOnBlur: false
    });

    useEffect(() => {
        const fields = ['name', 'email', 'password', 'confirmPassword'];
        fields.forEach(field => {
            const input = document.querySelector(`input[name="${field}"]`) as HTMLInputElement;
            if (input) {
                input.addEventListener('blur', () => {
                    formik.setFieldValue(field, input.value);
                });
            }
        });
    }, []);

    const checkEmailExists = async (email: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/email_exists/${email}`);
            const data = await response.json();
            setEmailExists(data.exists);
        } catch (error) {
            console.error('Error checking email:', error);
        }
    };

    useEffect(() => {
        if (formik.values.email) {
            checkEmailExists(formik.values.email);
        }
    }, [formik.values.email]);

    return (
        <Form
            onFinish={formik.handleSubmit}
            layout="vertical"
            style={{
                width: '100%',
                maxWidth: '400px',
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Title level={2} style={{ marginBottom: '24px' }}>Key Account</Title>
            <Space
                direction="vertical"
                size="large"
                style={{
                    width: '100%',
                }}
            >
                <Form.Item
                    name="name"
                    validateStatus={formik.errors.name ? 'error' : ''}
                    help={formik.errors.name}
                >
                    <Input
                        size="large"
                        placeholder='Enter your name'
                        {...formik.getFieldProps('name')}
                        onBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue('name', e.target.value);
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name="email"
                    validateStatus={(formik.errors.email || emailExists) ? 'error' : ''}
                    help={formik.errors.email || (emailExists ? 'This email is already in use.' : '')}
                >
                    <Input
                        size="large"
                        placeholder='Enter your email'
                        {...formik.getFieldProps('email')}
                        onBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue('email', e.target.value);
                            checkEmailExists(e.target.value);
                        }}
                        style={{
                            borderColor: emailExists ? '#ff4d4f' : undefined,
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    validateStatus={formik.errors.password ? 'error' : ''}
                    help={formik.errors.password}
                >
                    <Input.Password
                        size="large"
                        placeholder='Enter your password'
                        {...formik.getFieldProps('password')}
                        onBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue('password', e.target.value);
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name="confirmPassword"
                    validateStatus={formik.errors.confirmPassword ? 'error' : ''}
                    help={formik.errors.confirmPassword}
                >
                    <Input.Password
                        size="large"
                        placeholder='Confirm your password'
                        {...formik.getFieldProps('confirmPassword')}
                        onBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue('confirmPassword', e.target.value);
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name="token"
                    validateStatus={formik.errors.token ? 'error' : ''}
                    help={formik.errors.token}
                    style={{ marginBottom: '16px' }}
                >
                    <Input
                        size="large"
                        placeholder='Enter the verification token'
                        {...formik.getFieldProps('token')}
                        onBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue('token', e.target.value);
                        }}
                    />
                </Form.Item>

                <Form.Item style={{ marginBottom: '12px' }}>
                    <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                        size="large"
                        style={{ width: "100%" }}
                        disabled={emailExists}
                    >
                        Continue to the App
                    </Button>
                </Form.Item>
            </Space>
        </Form>
    );
};

interface KeyAccountSignupProps {
    loginCallback: () => void;
    isDarkMode: boolean;
    toggleTheme: () => void;
}

const KeyAccountSignup: React.FC<KeyAccountSignupProps> = ({ loginCallback, isDarkMode, toggleTheme }) => {
    return (
        <>
            <TopImages />
            <AbsoluteCenter>
                <Container>
                    <SignupFormContainer>
                        <Title style={{ textAlign: 'center' }}>Create an account</Title>
                        <KeyAccountSignupForm loginCallback={loginCallback} />
                        <p className="mt-4 text-center">
                            Already have an account?{' '}
                            <Link to="/login" style={{ color: isDarkMode ? 'white' : 'black' }}>
                                Go to Login
                            </Link>
                        </p>
                    </SignupFormContainer>
                    <div className="absolute bottom-4 right-4 flex items-center">
                        <button
                            onClick={toggleTheme}
                            className={`p-2 rounded-full ${isDarkMode ? 'bg-black hover:bg-gray-800' : 'bg-white hover:bg-gray-200'
                                } transition-colors border-none`}
                            aria-label="Toggle theme"
                        >
                            {isDarkMode ? (
                                <SunOutlined style={{ fontSize: '24px', color: 'white' }} />
                            ) : (
                                <SunFilled style={{ fontSize: '24px', color: 'black' }} />
                            )}
                            <p className={`mr-2 ${isDarkMode ? 'text-white' : 'text-black'}`}>Switch theme</p>
                        </button>
                    </div>
                </Container>
            </AbsoluteCenter>
        </>
    );
};

export default KeyAccountSignup;