import { LoadingOutlined, UpCircleFilled, UpCircleOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import { Template } from '../../services/templates';
import TemplateMenu from './TemplateMenu';
import { SendButton } from '../basic/send-container';
import { styled } from '@stitches/react';

const ChatInputContainer = styled("div", {
    position: "fixed",
    bottom: "24px", // 6 in rem
    left: "50%",
    transform: "translateX(-50%)",
    width: "70vh",
    maxWidth: "calc(100% - 48px)", // Account for margin
    zIndex: 10,
    // borderRadius: "16px",
    padding: "12px",
    boxSizing: "border-box",
    transition: "height 0.2s ease-out" // Smooth height transitions
});

interface ChatInputProps {
    input: string;
    setInput: (value: string) => void;
    isLoadingMessages: boolean;
    sendMessage: () => void;
    handleKeyDown: (e: any) => void;
    templates: Template[];
    onUpdateTemplates: (updatedTemplates: Template[]) => void;
    chatTypeSpecifier: string;
    handleSetMessageText: (text: string) => void;
    selectedWeeks: number[];
    token: any;
}

const ChatInput: React.FC<ChatInputProps> = ({
    input,
    setInput,
    isLoadingMessages,
    sendMessage,
    handleKeyDown,
    templates,
    onUpdateTemplates,
    chatTypeSpecifier,
    handleSetMessageText,
    selectedWeeks,
    token
}) => {
    return (
        <ChatInputContainer>
            <div className="flex flex-col">
                <div
                    className="text-right flex items-right justify-end gap-2 mb-2"
                    style={{ marginRight: '0.75rem' }}
                >
                    <TemplateMenu
                        onSetMessageText={handleSetMessageText}
                        templates={templates}
                        onUpdateTemplates={onUpdateTemplates}
                        chatTypeSpecifier={chatTypeSpecifier}
                    />
                </div>
            </div>

            <div style={{ position: "relative" }}>
                <Input.TextArea
                    size="large"
                    autoSize={{ minRows: 1, maxRows: 3 }}
                    placeholder="Send message..."
                    value={input}
                    onChange={e => setInput(e.target.value.replace(/^\s+/, ''))}
                    onKeyDown={handleKeyDown}
                    style={{
                        borderRadius: '0.75rem',
                        width: '100%',
                        resize: 'none',
                        maxHeight: '4.5rem', // Enforce max height for 3 rows
                        overflowY: 'auto'
                    }}
                />
                <Tooltip
                    title={isLoadingMessages ? "Please wait while the message is being processed" :
                        selectedWeeks.length === 0 ? "Please select at least one module" : "Press Shift + Enter to create a new line"}
                >
                    <SendButton
                        data-testid="send-button"
                        size="large"
                        type="text"
                        disabled={isLoadingMessages || selectedWeeks.length === 0}
                        onClick={sendMessage}
                        style={{
                            transition: 'background-color 0.3s ease',
                            color: token.colorPrimary,
                            position: 'absolute',
                            right: '0',
                            bottom: '0px'
                        }}
                        onMouseEnter={(event: { currentTarget: { style: { backgroundColor: string; color: any; }; }; }) => {
                            event.currentTarget.style.backgroundColor = 'transparent';
                            event.currentTarget.style.color = token.colorPrimaryHover;
                        }}
                        onMouseLeave={(event: { currentTarget: { style: { backgroundColor: string; color: any; }; }; }) => {
                            event.currentTarget.style.backgroundColor = 'transparent';
                            event.currentTarget.style.color = token.colorPrimary;
                        }}
                    >
                        {isLoadingMessages ? <LoadingOutlined style={{ color: token.colorPrimary }} /> :
                            input.length > 0 ? <UpCircleFilled style={{ fontSize: '24px' }} /> :
                                <UpCircleOutlined style={{ fontSize: '24px' }} />}
                    </SendButton>
                </Tooltip>
            </div>
        </ChatInputContainer>
    );
};

export default ChatInput;