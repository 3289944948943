import { useLocation } from 'react-router-dom';
import { Container, SignupFormContainer, TopImages } from '../../components/session-styles';
import SignupForm from '../../components/signup/signup-form';
import { AbsoluteCenter } from '../../components/styles';

export interface SignupProps {
    loginCallback: () => void;
    referralCode: string | null;
    isDarkMode: boolean;
    toggleTheme: () => void;
}

const SignupScreen: React.FC<SignupProps> = ({ loginCallback, isDarkMode, toggleTheme }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get('referral_code');

    console.log("SignupScreen is rendering");
    return (
        <>
            <TopImages />
            <AbsoluteCenter>
                <Container>
                    <SignupFormContainer>
                        <SignupForm
                            loginCallback={loginCallback}
                            referralCode={referralCode}
                            isDarkMode={isDarkMode}
                            toggleTheme={toggleTheme} />
                    </SignupFormContainer>
                </Container>
            </AbsoluteCenter>
        </>
    );
};

export default SignupScreen;