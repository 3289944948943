import React from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { Week } from '../../services/courses';

interface AssignmentMenuProps {
  weeks: Week[];
  onAssignmentSelect: (weekNumber: number) => void;
}

const AssignmentMenu: React.FC<AssignmentMenuProps> = ({
  weeks,
  onAssignmentSelect
}) => {
  const filteredWeeks = weeks.filter(week => week.assignment && week.assignment.trim() !== '');

  const items: MenuProps['items'] = [{
    label: 'Assignments',
    key: 'select-assignments',
    icon: <FileOutlined />,
    children: filteredWeeks.length > 0
      ? filteredWeeks.map((week) => ({
          key: `assignment-${week.number}`,
          label: `Module ${week.number} Assignment${week.title ? `: ${week.title}` : ''}`,
          onClick: () => {
            onAssignmentSelect(week.number);
          }
        }))
      : []
  }];

  return (
    <Menu
      style={{
        overflow: 'visible',
        border: 'none',
        boxShadow: 'none',
        color: 'inherit',
      }}
      mode="inline"
      items={items}
      selectedKeys={[]} // This ensures no items are ever shown as selected
      onSelect={({ key, selectedKeys }) => {
        // Immediately clear any selection that might occur
        return { selectedKeys: [] };
      }}
    />
  );
};

export default AssignmentMenu;