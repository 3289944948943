import { Input, Modal, message, theme } from 'antd';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { storeQuickSupport } from '../services/feedback';
import { getUserDataFromLocalStorage } from '../utils/useLocalStorage';
import { CopyOutlined, MailOutlined, MessageOutlined, PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { FlexMarginButton, NoMarginButton } from './basic/buttons';

interface QuickQuestionModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const WhatsappInvite: React.FC<{ color: string }> = ({ color }) => (
  <div className="whatsapp-invite" style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
    border: 'none',
    marginTop: '20px'
  }}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>Want to talk to us directly?</div>
      <div>Join us on WhatsApp:</div>
    </div>
    <a href="https://chat.whatsapp.com/HN2jIKlap9RGAkuFIcrzf6" 
       target="_blank" 
       rel="noopener noreferrer">
      <WhatsAppOutlined style={{ fontSize: '100px', color: color }} />
    </a>
  </div>
);

const QuickQuestionModal: React.FC<QuickQuestionModalProps> = ({ isOpen, setIsOpen }) => {
  const user = getUserDataFromLocalStorage();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showWhatsApp, setShowWhatsApp] = useState(false);
  const { token } = theme.useToken();

  const formik = useFormik({
    initialValues: { text: '' },
    onSubmit: async (values, { resetForm }) => {
      if (!values.text.trim()) {
        message.error('Please enter a message');
        return;
      }
      
      setSubmitLoading(true);
      try {
        await storeQuickSupport(values.text, user?._id);
        resetForm();
        setShowWhatsApp(true);
        message.success('We are on it!');
      } catch (error) {
        message.error('Failed to send message');
      } finally {
        setSubmitLoading(false);
      }
    }
  });

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
        setShowWhatsApp(false);
        formik.resetForm();
      }}
      footer={null}
      width={400}
      title={
        <div>
          <h2 style={{ fontSize: '24px', fontWeight: 'bold', margin: 0 }}>Get in Touch</h2>
          <p style={{ fontSize: '16px', marginTop: '8px' }}>
            Choose how you'd like to connect with us
          </p>
        </div>
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingTop: '8px' }}>
        <FlexMarginButton
          block
          icon={<PhoneOutlined />}
          onClick={() => window.open('https://cal.com/infolab.ai/support', '_blank')}
          style={{ textAlign: 'left', height: 'auto', padding: '8px 16px', justifyContent: 'flex-start' }}
        >
          Book a call with us
        </FlexMarginButton>
        
        <FlexMarginButton
          block
          icon={<MessageOutlined />}
          onClick={() => window.open('https://chat.whatsapp.com/HN2jIKlap9RGAkuFIcrzf6', '_blank')}
          style={{ textAlign: 'left', height: 'auto', padding: '8px 16px', justifyContent: 'flex-start' }}
        >
          Join our WhatsApp group
        </FlexMarginButton>

        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <NoMarginButton
            block
            icon={<MailOutlined />}
            onClick={() => window.open('mailto:team@infolab.ai', '_self')}
            style={{
              textAlign: 'left',
              height: '100%',
              padding: '8px 16px',
              justifyContent: 'flex-start',
              borderRadius: '4px 0 0 4px',
              flex: '1'
            }}
          >
            Email us
          </NoMarginButton>
          <NoMarginButton
            icon={<CopyOutlined />}
            onClick={() => {
              navigator.clipboard.writeText('team@infolab.ai');
              message.success('Email copied!');
            }}
            style={{
              height: '100%',
              padding: '8px 16px',
              justifyContent: 'center',
              borderRadius: '0 4px 4px 0',
              flex: '0 0 15%'
            }}
          />
        </div>

        {!showWhatsApp ? (
          <form onSubmit={formik.handleSubmit}>
            <p style={{ fontSize: '16px', marginTop: '8px' }}>
              Or simply send us a message:
            </p>
            <Input.TextArea
              size="large"
              id="text"
              placeholder="Your feedback is appreciated"
              name="text"
              value={formik.values.text}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ marginBottom: '16px' }}
            />
            <FlexMarginButton
              loading={submitLoading}
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: "100%" }}
            >
              Submit
            </FlexMarginButton>
          </form>
        ) : (
          <WhatsappInvite color={token.colorPrimary} />
        )}
      </div>
    </Modal>
  );
};

export default QuickQuestionModal;