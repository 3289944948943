import { MessageFilled } from '@ant-design/icons';
import { styled } from '@stitches/react';
import { Layout, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddCourseCard from '../../components/course/add-course-card';
import CourseCard from '../../components/course/course-card';
import { newConversation } from '../../services/conversations';
import { Course, getUserCourses } from '../../services/courses';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';

const { Content } = Layout;

export const CardsContainer = styled("div", {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))", // Maintains a grid with a flexible number of columns
    gridGap: "50px", // Space between cards
    padding: "10px", // Padding inside the grid container
    alignItems: "start", // Aligns grid items to the top of the container
    justifyContent: "start", // Aligns grid items to the left of the container
    maxWidth: "100%",
    boxSizing: "border-box",
});

const CourseOverview: React.FC = () => {
    const user = getUserDataFromLocalStorage();
    const [courses, setCourses] = useState<Course[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const coursesData = await getUserCourses(user._id);
                setCourses(coursesData);
            } catch (error) {
                console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
            }
        }
        fetchCourses().then(() => setIsLoading(false));
    }, []);

    return (
        <Content style={{ padding: "2rem"}}>
            {isLoading && <Spin size="large" />}
            {!isLoading &&
                <CardsContainer>
                    <AddCourseCard />
                    {courses.map(course => (
                        <CourseCard
                            key={course._id}
                            id={course._id}
                            title={course.name}
                            description={course.description}
                            professors={course.professors}
                            lastUpdated={course.last_updated}
                            cta={{
                                text: "Chat",
                                icon: <MessageFilled />,
                                onClick: () => {
                                    newConversation(course._id!, user._id, "content").then(chat => navigate("/chat/" + chat._id));
                                },
                            }}
                        />
                    ))}
                </CardsContainer>
            }
        </Content>
    );
};

export default CourseOverview;
