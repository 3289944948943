import { QuestionCircleOutlined, AppstoreOutlined, FileOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { styled } from "@stitches/react";
import { Button, Col, Divider, Layout, Menu, MenuProps, Modal, Row, Tooltip } from "antd";
import 'katex/dist/katex.min.css'; // Import KaTeX CSS
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { theme } from 'antd';

import { v4 as uuidv4 } from 'uuid';
import { Conversation, Message, getConversation, updateConversationTemplates } from "../../services/conversations";
import { Course, getCourse, Week } from "../../services/courses";
import { capitalize } from "../../utils/utils";
import authedAxios from "../../services/auth-axios";
import { MessageBubble } from "../../components/chat/MessageBubble";
import { BackendTemplate, Template } from "../../services/templates";
import CourseReportsPage from "../analytics/CourseReportsPage";
import { updateUserTemplates } from "../../services/user";
import useUserData from "../../hooks/useUser";
import ChatInput from "../../components/chat/chat-input";
import Title from "antd/es/typography/Title";
import AssignmentMenu from "../../components/chat/AssignmentMenu";

const chatSiderWidth = "33vh";

const { Content } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const Container = styled(Content, {
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Centers children horizontally
    // alignItems: "flex-start",
    // justifyContent: "center",
    height: "100vh",
    width: `calc(100vh - ${chatSiderWidth} / 2)`, // Using calc() to subtract chatSiderWidth from 100vh
    margin: "0 auto",
    gap: "20px",
    padding: "2rem",
    boxSizing: "border-box",
    // position: "relative", // Added to help with absolute positioning of chat input
    overflow: "hidden", // Prevent container from scrolling
})

const HeaderContainer = styled("div", {
    display: "flex",
    width: '70vh',
    height: '10vh',
    minHeight: "10vh",
    maxHeight: "10vh",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "10px 0",
    borderBottom: "1px solid #e0e0e0",
    boxSizing: "border-box",
    overflow: "hidden" // Allow scrolling if content exceeds maxHeight
});

const CourseDescription = styled("p", {
    margin: "1 0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%"
});

const MessagesContainer = styled("div", {
    width: '80vh',
    maxWidth: '100%',
    flex: "1 1 auto", // Allow container to grow and shrink
    minHeight: "0", // Required for flex child to scroll
    // height: "75vh",
    // maxHeight: "80vh",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: "calc(48px + 3.5rem)", // Account for input height (3 rows max) + padding
});

type ChatInterfaceProps = {
    type: string;
    endpointUrl: string;
    initialMessage?: string;
};

const ChatInterface = ({ type: chatTypeSpecifier, endpointUrl: endpointUrlSpecifier, initialMessage: initialMessageSpecifier }: ChatInterfaceProps) => {
    const { chatID } = useParams();
    const { user, updateUserDataTemplates } = useUserData();
    const [messages, setMessages] = useState<Message[]>([])
    const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(false)
    const [input, setInput] = useState<string>("")
    const [conversation, setConversation] = useState<Conversation | undefined>(undefined)

    const [course, setCourse] = useState<Course | undefined>(undefined)

    const [weekMenuItems, setWeekMenuItems] = useState<MenuItem[]>([]);
    const [selectedWeeks, setSelectedWeeks] = useState<number[]>([]);

    const [groupMenuItems, setGroupMenuItems] = useState<MenuItem[]>([]);
    const [selectedGroups, setSelectedGroup] = useState<string[]>([]);

    const [materialMenuItems, setMaterialMenuItems] = useState<MenuItem[]>([]);
    const [selectedMaterials, setSelectedMaterials] = useState<string[]>([]);

    const [templates, setTemplates] = useState<Template[]>([]);

    const [error, setError] = useState<string | null>(null);
    const [overlayVisible, setOverlayVisible] = useState<boolean>(false); // State for overlay visibility
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const { useToken } = theme;
    const { token } = useToken();

    const sider_tooltip = <span>{`Use the Navigator to access and manage modules, groups, materials, and assignments for efficient ${user.config.course_terminology.toLowerCase()} organization and quick content selection`}</span>;
    const main_tooltip = (
        <span>
            What can you do with this AI? <br /><br />
            {`You can chat with all the documents uploaded by your educators to the selected ${user.config.module_terminology.toLowerCase()}s. If no ${user.config.module_terminology.toLowerCase()} is selected, you are chatting to all the information available to the system. `}<br />
            {`Answers will provide the source, including a page number or timestamp, the relevant ${user.config.module_terminology.toLowerCase()}, and a relevancy score. The relevancy score may not align with your perspective as it is an auto-calculated score. `}<br /><br />
            You can further ask the AI: <br />
            <ul>
                <li>{`for a summary of the selected ${user.config.module_terminology.toLowerCase()}s.`}</li>
                <li>{`for a list of the documents in the selected ${user.config.module_terminology.toLowerCase()}s.`}</li>
                <li>{`the names of the educators of the ${user.config.course_terminology.toLowerCase()}.`}</li>
            </ul><br />
            Do you think a function is missing? Reach out to us using the feedback button in the bottom left. We are eager to improve!
        </span>
    );

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    useEffect(() => {
        setIsLoadingMessages(true)
        getConversation(chatID!).then(c => {
            setConversation(c)
        })
    }, [chatID])


    useEffect(() => {
        if (!conversation) return;
        getCourse(conversation.course_id!).then((course) => {
            setIsLoadingMessages(true);
            setCourse(course);
            setIsLoadingMessages(false);
        });
        // Update templates to ensure each has a ui_key
        const updatedTemplates = (conversation.templates || [])
            .filter(template => template.type === chatTypeSpecifier) // Filter by ChatInterface type
            .map(template => ({
                ...template,
                ui_key: template.ui_key || uuidv4()
            }));
        setTemplates(updatedTemplates);
    }, [conversation])

    useEffect(() => {
        if (!conversation) return;
        setIsLoadingMessages(true);
        let initialMessage = {
            state_id: uuidv4(),
            msg_id: uuidv4(),
            user: "system",
            text: initialMessageSpecifier ? initialMessageSpecifier : `Hello ${user.name}, how are you?\n\nWhat would like to learn today?`,
            sources: [],
            timestamp: new Date().toISOString()
        } as Message

        setMessages(
            (conversation.messages && conversation.messages.length > 0)
                ? conversation.messages
                : [initialMessage]
        );
        setIsLoadingMessages(false);
    }, [conversation])

    useEffect(() => {
        if (course) {
            const currentDate = new Date();
            const relevantWeekItems = course.weeks
                .filter(week => new Date(week.start_date) <= currentDate)
                .map((week) => ({
                    label: `${capitalize(user.config.module_terminology)} ${week.number}`,
                    key: week.number,
                }));

            // Prepend "Select All" to the top if there are at least two weeks
            if (relevantWeekItems.length >= 2) {
                relevantWeekItems.unshift({
                    label: 'Select All',
                    key: 0, // Key for "Select All"
                });
            }

            let newWeekMenuItems = [{
                label: `Select ${capitalize(user.config.module_terminology)}s`,
                key: 'select-modules',
                children: [
                    ...relevantWeekItems,
                ],
                icon: <FolderOpenOutlined />,
            }];

            if (relevantWeekItems.length > 0) {
                const indexToSelect = Math.min(1, relevantWeekItems.length - 1);
                let initialSelectedWeeks = [relevantWeekItems[indexToSelect].key as number];
                setSelectedWeeks(initialSelectedWeeks);
                updateRelevantWeekNumbers(initialSelectedWeeks);
            } else {
                setSelectedWeeks([]);
            }
            setWeekMenuItems(newWeekMenuItems);
        }
    }, [course]);

    const onWeekSelectionClick: MenuProps['onClick'] = (e) => {
        let updatedSelectedWeeks = [...selectedWeeks];
        const clickedWeekNumber = parseInt(e.key);

        if (clickedWeekNumber === 0) {
            // "Select All" logic
            if (course && updatedSelectedWeeks.length === course.weeks.length) {
                // If all weeks are already selected, deselect all
                updatedSelectedWeeks = [];
            } else {
                // Else, select all weeks
                updatedSelectedWeeks = course ? course.weeks.map((_, index) => index + 1) : [];
            }
        } else {
            // Individual week selection logic
            if (updatedSelectedWeeks.includes(clickedWeekNumber)) {
                // If already selected, remove from selection
                updatedSelectedWeeks = updatedSelectedWeeks.filter(week => week !== clickedWeekNumber);
            } else {
                // If not selected, add to selection
                updatedSelectedWeeks.push(clickedWeekNumber);
            }
        }

        if (updatedSelectedWeeks.length === 0 && course!.weeks.length > 1) {
            if (clickedWeekNumber === 0) {
                // If all weeks are deselected via Select All, select the last week
                updatedSelectedWeeks.push(course!.weeks.length);
            } else {
                // If no weeks are selected, select the clicked week
                updatedSelectedWeeks.push(clickedWeekNumber);
            }
        }

        setSelectedWeeks(updatedSelectedWeeks); // Update state
        updateRelevantWeekNumbers(updatedSelectedWeeks); // Call backend with the updated list
        // Update group menu items
        const selectedWeekData = course!.weeks.filter(week => updatedSelectedWeeks.includes(week.number));

        generateMenuItems(selectedWeekData);
    };

    function generateMenuItems(selectedWeekData: Week[]) {
        const uniqueGroups = Array.from(new Set(selectedWeekData.flatMap(week =>
            week.materials ? week.materials.map(material => material.group).filter(group => group !== "") : []
        )));

        const newGroupMenuItems = [{
            label: `Select Groups`,
            key: 'select-groups',
            children: uniqueGroups.map(group => ({
                label: `Group ${group}`,
                key: group,
            })),
            icon: <AppstoreOutlined />,
        }];
        setGroupMenuItems(newGroupMenuItems);

        const allMaterials = selectedWeekData.flatMap(week => week.materials || []);
        const uniqueMaterialsMap = new Map(allMaterials.map(material => [material.file_id, material]));

        const newMaterialMenuItems = [{
            label: `Select Materials`,
            key: 'select-materials',
            children: Array.from(uniqueMaterialsMap.values()).map(material => ({
                label: material.name,
                key: material.file_id,
            })),
            icon: <FileOutlined />,
        }];

        setMaterialMenuItems(newMaterialMenuItems);
    };

    const onGroupSelectionClick: MenuProps['onClick'] = (e) => {
        let updatedSelectedGroups = [...selectedGroups];
        const clickedGroup = e.key;

        if (updatedSelectedGroups.includes(clickedGroup)) {
            // If already selected, remove from selection
            updatedSelectedGroups = updatedSelectedGroups.filter(group => group !== clickedGroup);
        } else {
            // If not selected, add to selection
            updatedSelectedGroups.push(clickedGroup);
        }

        setSelectedGroup(updatedSelectedGroups); // Update state
        updateRelevantGroups(updatedSelectedGroups); // Call backend with the updated list
    };

    const onMaterialSelectionClick: MenuProps['onClick'] = (e) => {
        let updatedSelectedMaterials = [...selectedMaterials];
        const clickedMaterialFileId = e.key;

        if (updatedSelectedMaterials.includes(clickedMaterialFileId)) {
            // If already selected, remove from selection
            updatedSelectedMaterials = updatedSelectedMaterials.filter(fileId => fileId !== clickedMaterialFileId);
        } else {
            // If not selected, add to selection
            updatedSelectedMaterials.push(clickedMaterialFileId);
        }

        setSelectedMaterials(updatedSelectedMaterials); // Update state
        updateRelevantFileIds(updatedSelectedMaterials); // Call backend with the updated list
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            if (e.shiftKey) {
                // Allow default behavior (new line) when Shift+Enter is pressed
                return;
            } else {
                e.preventDefault(); // Prevent the default action (new line)
                sendMessage();
            }
        }
    };

    const handleSetMessageText = (text: string) => {
        setInput(text);  // Set the input state which is bound to the message input field
    };

    function sendMessage() {
        if (input === "") return;

        let new_msg_id = uuidv4()
        const newUserMessage = {
            state_id: uuidv4(),
            msg_id: new_msg_id,
            user: "user",
            text: input,
            sources: [],
            timestamp: new Date().toISOString()
        } as Message;

        setInput("");
        setIsLoadingMessages(true);

        setMessages(prevMessages => [...prevMessages, newUserMessage]);

        const token = localStorage.getItem('access_token');
        if (!token) {
            throw new Error("No access token available");
        }

        (async () => {
            try {
                const textDecoder = new TextDecoder();
                const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${endpointUrlSpecifier}/${chatID}/chat?user_query=${encodeURIComponent(input)}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (res.body) {
                    const reader = res.body.getReader();

                    while (true) {
                        const { value, done } = await reader.read();
                        if (done) break;

                        const text_value = textDecoder.decode(value, { stream: true });

                        // Update the message with the accumulated text
                        updateOrAddMessage(new_msg_id, text_value);
                    }
                } else {
                    throw new Error("No body");
                }
            } catch (error: any) {
                console.error('Error:', error);
                setError(error.message);
            } finally {
                setIsLoadingMessages(false)
            }
        })()
    }

    function updateOrAddMessage(new_msg_id: string, new_token: string) {
        // This function updates the last message without adding a new one. This facilitates streaming in the UI.
        setMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            const prevMessagesExceptLast = prevMessages.slice(0, prevMessages.length - 1);
            const updatedMessages = prevMessages.length > 0 && lastMessage.user === "bot"
                ? [...prevMessagesExceptLast, {
                    state_id: uuidv4(),
                    msg_id: new_msg_id,
                    user: 'bot',
                    text: lastMessage.text + new_token,
                    sources: [],
                    timestamp: new Date().toISOString()
                } as Message]
                : [...prevMessages, {
                    state_id: uuidv4(),
                    msg_id: new_msg_id,
                    user: 'bot',
                    text: new_token,
                    sources: [],
                    timestamp: new Date().toISOString()
                } as Message];
            return updatedMessages;
        });
    }

    async function updateRelevantWeekNumbers(weekNumbers: number[]) {
        try {
            const response = await authedAxios.put(`/conversations/${chatID}/update_relevant_week_numbers`,
                weekNumbers,
            );

            if (response.status !== 200) {
                throw new Error("Failed to update conversation with relevant weeks");
            }

            console.log("Conversation updated successfully:", response.data);
        } catch (error) {
            console.error("Error updating conversation:", error);
        }
    }

    async function updateRelevantGroups(groups: string[]) {
        try {
            const response = await authedAxios.put(`/conversations/${chatID}/update_relevant_groups`,
                groups,
            );

            if (response.status !== 200) {
                throw new Error("Failed to update conversation with groups");
            }

            console.log("Conversation updated successfully:", response.data);
        } catch (error) {
            console.error("Error updating conversation:", error);
        }
    }

    async function updateRelevantFileIds(file_ids: string[]) {
        try {
            const response = await authedAxios.put(`/conversations/${chatID}/update_relevant_file_ids`,
                file_ids,
            );

            if (response.status !== 200) {
                throw new Error("Failed to update conversation with file_ids");
            }

            console.log("Conversation updated successfully:", response.data);
        } catch (error) {
            console.error("Error updating conversation:", error);
        }
    }

    const handleUpdateTemplates = async (updatedTemplates: Template[]) => {
        if (conversation) {
            const templatesToSend = updatedTemplates.map(({ ui_key, ...backend_template }) => backend_template);

            try {
                const conversationUpdated = await updateConversationTemplates(conversation._id, templatesToSend);
                if (conversationUpdated) {
                    console.log("Templates updated successfully");
                    setTemplates(updatedTemplates);
                    setConversation(prevConversation => ({
                        ...prevConversation!,
                        templates: updatedTemplates
                    }));
                } else {
                    console.error("Failed to update conversation templates");
                }

                const userTemplatesToSend = [
                    // Include templates in templatesToSend that are also in user.templates
                    ...templatesToSend.filter(template =>
                        user.templates.some((userTemplate: BackendTemplate) => userTemplate.name === template.name)
                    ),
                    // Include all new templates that are not in course.templates
                    ...templatesToSend.filter(template =>
                        !conversation?.templates.some(courseTemplate => courseTemplate.name === template.name)
                    )
                ];

                updateUserDataTemplates(userTemplatesToSend);
                if (userTemplatesToSend.length > 0) {
                    const userUpdated = await updateUserTemplates(user._id, userTemplatesToSend);
                    if (userUpdated) {
                        console.log("User templates updated successfully");
                    } else {
                        console.error("Failed to update user templates");
                    }
                }
            } catch (error) {
                console.error('Error updating templates:', error);
            }
        }
    };

    return (<>
        <Layout style={{ height: '100vh', overflow: 'hidden' }}>
            <Layout.Content>
                <Container>
                    {course &&
                        <HeaderContainer>
                            <div style={{ width: '90%' }}>
                                <Title
                                    level={2}
                                    style={{ 
                                        margin: 0, 
                                        fontSize: '24px' 
                                    }}
                                >
                                    {course.name}
                                </Title>
                                <Tooltip title={course.description}>
                                    <CourseDescription>
                                        {course.description}
                                    </CourseDescription>
                                </Tooltip>
                            </div>
                            <div style={{
                                width: '10%',
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: "nowrap",
                                maxWidth: "100%",
                                flexDirection: "row",
                                alignItems: "flex-start",
                                padding: "0px 0px",
                            }}>
                                <Tooltip placement="bottomRight" title={main_tooltip} overlayStyle={{ maxWidth: '50vh' }}>
                                    <Button
                                        icon={<QuestionCircleOutlined />}
                                        style={{
                                            flexShrink: 0,
                                            height: '100%',
                                            margin: 5,
                                            width: '100%',
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </HeaderContainer>
                    }
                    {course &&
                        <MessagesContainer>
                            {messages.map((msg) => {
                                return (
                                    <MessageBubble
                                        key={msg.state_id}
                                        conversationId={conversation!._id}
                                        msgId={msg.msg_id}
                                        user={msg.user}
                                        text={msg.text}
                                        sources={msg.sources}
                                        type={chatTypeSpecifier}
                                    />
                                );
                            })}
                            <div ref={messagesEndRef} /> {/* Dummy div for scrolling */}
                        </MessagesContainer>
                    }
                    <ChatInput
                        input={input}
                        setInput={setInput}
                        isLoadingMessages={isLoadingMessages}
                        sendMessage={sendMessage}
                        handleKeyDown={handleKeyDown}
                        templates={templates}
                        onUpdateTemplates={handleUpdateTemplates}
                        chatTypeSpecifier={chatTypeSpecifier}
                        handleSetMessageText={handleSetMessageText}
                        selectedWeeks={selectedWeeks}
                        token={token}
                    />
                </Container>
                {chatTypeSpecifier === 'analytics' &&
                    <Modal
                        title="Course Reports"
                        open={overlayVisible}
                        onCancel={() => setOverlayVisible(false)}
                        footer={null}
                        width="60%"
                        destroyOnClose={true}
                        style={{
                            display: 'flex',
                            top: 20,
                            justifyContent: 'center'
                        }}
                    >
                        {process.env.REACT_APP_COURSE_IDS?.split(',').includes(user._id!) ? (
                            <CourseReportsPage />
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    top: 20,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    minWidth: '20%', // Increased width
                                    flexDirection: 'column', // Center vertically
                                }}>
                                Coming soon!
                            </div>
                        )}
                    </Modal>
                }
            </Layout.Content>
            <Layout.Sider
                width={chatSiderWidth}
                style={{ overflow: 'auto', overflowX: 'hidden' }}>
                <Row justify="space-between">
                    <Col span={22} push={1}>
                        <div
                            style={{
                                textAlign: 'left',
                                width: '90%',
                                height: '100%',
                                border: 'none',
                                boxShadow: 'none',
                                color: 'inherit',
                                backgroundColor: 'transparent',
                                marginBottom: '0.5rem',
                            }}
                        >
                            <Title level={4} className="!mb-0">Navigator</Title>
                        </div>
                    </Col>
                    <Col span={2}>
                        <Tooltip placement="left" title={sider_tooltip}>
                            <Button
                                icon={<QuestionCircleOutlined />}
                                style={{
                                    flexShrink: 0,
                                    height: '100%',
                                    border: 'none',
                                    padding: 0,
                                    width: '10%',
                                }}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                <Divider
                    style={{ margin: 0 }}>
                </Divider>
                <AssignmentMenu
                    weeks={course?.weeks || []}
                    onAssignmentSelect={(weekNumber) => {
                        const selectedWeek = course?.weeks.find(week => week.number === weekNumber);
                        if (selectedWeek) {
                            setMessages(prev => [...prev, {
                                state_id: uuidv4(),
                                msg_id: uuidv4(),
                                user: "bot",
                                text: selectedWeek.assignment || "No assignment for this week.",
                                sources: [],
                                timestamp: new Date().toISOString()
                            }]);
                        }
                    }}
                />
                <Divider
                    style={{ margin: 0 }}>
                </Divider>
                {course && course.weeks.length > 0 &&
                    (
                        <div style={{
                            overflow: 'visible'
                        }}>
                            <Menu
                                style={{
                                    overflow: 'visible',
                                    border: 'none',
                                    boxShadow: 'none',
                                    color: 'inherit',
                                }}
                                onClick={onWeekSelectionClick}
                                selectedKeys={selectedWeeks.map(week => week.toString())}
                                defaultOpenKeys={['select-modules']}
                                mode="inline"
                                items={weekMenuItems} />
                        </div>
                    )
                }
                {chatTypeSpecifier === "content" && course && course.weeks.length > 0 && selectedWeeks.length > 0 &&
                    (
                        <div style={{
                            overflow: 'visible',
                        }}>
                            {user.config.filter_by_group &&
                                <Menu
                                    style={{
                                        overflow: 'visible',
                                        border: 'none',
                                        boxShadow: 'none',
                                        color: 'inherit',
                                    }}
                                    onClick={onGroupSelectionClick}
                                    selectedKeys={selectedGroups.map(group => group.toString())}
                                    defaultOpenKeys={['select-groups']}
                                    mode="inline"
                                    items={groupMenuItems} />
                            }
                            {user.config.filter_by_source &&
                                <Menu
                                    style={{
                                        overflow: 'visible',
                                        border: 'none',
                                        boxShadow: 'none',
                                        color: 'inherit',
                                    }}
                                    onClick={onMaterialSelectionClick}
                                    selectedKeys={selectedMaterials.map(material => material.toString())}
                                    defaultOpenKeys={['select-materials']}
                                    mode="inline"
                                    items={materialMenuItems} />
                            }
                        </div>
                    )
                }
            </Layout.Sider>
        </Layout>
    </>)
}


export default ChatInterface;