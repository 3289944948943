import React, { useEffect, useState } from 'react';
import { Modal, Input, message, Row, Col, Button, Divider } from 'antd';
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from '../../utils/useLocalStorage';
import { updatePassword, updateUserEmail, updateUserName } from '../../services/user';
import { Content } from 'antd/es/layout/layout';
import { SaveButton } from '../../components/basic/buttons';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';

const UserSettingsPage = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [savingLoading, setSavingLoading] = useState<boolean>(false);

    const [hasChanges, setHasChanges] = useState<boolean>(false);

    const user = getUserDataFromLocalStorage();
    const [newUserName, setNewUserName] = useState(user.name); 
    const [newUserEmail, setNewUserEmail] = useState(user.email); 

    const referralLink = `${window.location.origin}/signup?referral_code=${user.referrals.code}`;

    useEffect(() => {
        const user = getUserDataFromLocalStorage();
        setHasChanges(newUserName !== user.name
            || newUserEmail !== user.email
        );
    }, [newUserName, newUserEmail]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        if (newPassword !== confirmPassword) {
            message.error("New passwords do not match!");
            return;
        }
        try {
            await updatePassword(user.id, { old_password: oldPassword, new_password: newPassword });
            message.success("Password updated successfully!");
            setIsModalVisible(false);
        } catch (error) {
            message.error("Failed to update password. Please try again.");
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const saveChanges = async () => {
        setSavingLoading(true);
        try {
            if (newUserName !== user.name) {
                const success = await updateUserName(user._id, { name: newUserName });
                if (success) {
                    user.name = newUserName;
                    setUserDataInLocalStorage(user);
                }
            }
            if (newUserEmail !== user.email) {
                const success = await updateUserEmail(user._id, { email: newUserEmail });
                if (success) {
                    user.name = newUserEmail;
                    setUserDataInLocalStorage(user);
                }
            }
            message.success("User information updated successfully!");
            setHasChanges(false);
        } catch (error) {
            message.error("Failed to update user information. Please try again.");
        } finally {
            setSavingLoading(false);
        }
    };

    return (
        <Content style={{ padding: "2rem" }}>
            <Title
                level={1}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                Settings
            </Title>
            {hasChanges && <SaveButton loading={savingLoading} type="primary" size="large" onClick={saveChanges} >Save Changes</SaveButton>}
            <Row align="middle" gutter={[8, 24]}>
                <Col span={4}>
                    <Paragraph strong>Name: </Paragraph>
                </Col>
                <Col span={4}>
                    <Input value={newUserName} onChange={(e) => setNewUserName(e.target.value)} />
                </Col>
            </Row>
            <Row align="middle" gutter={[8, 24]}>
                <Col span={4}>
                    <Paragraph strong>Email: </Paragraph>
                </Col>
                <Col span={4}>
                    <Input value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} />
                </Col>
            </Row>
            <Row align="middle" gutter={[8, 24]}>
                <Col span={4}>
                    <Paragraph strong>Password: </Paragraph>
                </Col>
                <Col span={4}>
                    <Button type="primary" onClick={showModal} style={{ width: '100%' }}> {/* Added style to make button full width */}
                        Change Password
                    </Button>
                </Col>
            </Row>
            <Divider />
            <Title
                level={1}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                Referrals
            </Title>
            <Row align="middle" gutter={[8, 24]}>
                <Col span={4}>
                    <Paragraph strong>Number of referals: </Paragraph>
                </Col>
                <Col span={4}>
                <Paragraph >{user.referrals.count}</Paragraph>
                </Col>
            </Row>
            <Row align="middle" gutter={[8, 24]}>
                <Col span={4}>
                    <Paragraph strong>Referral link: </Paragraph>
                </Col>
                <Col span={4}>
                <Paragraph >{referralLink}</Paragraph>
                </Col>
            </Row>
            <Modal title="Change Password" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <div style={{ marginBottom: '1rem' }}>
                    <Input.Password
                        placeholder="Old Password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <Input.Password
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <Input.Password
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
            </Modal>
        </Content>
    );
};

export default UserSettingsPage;
